<template>
    <div class="modal active" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Smart Week <span>Add Smart Week</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <quote-loader v-if="loader" />
                <div class="smart_weeklist" v-show="!loader">
                    <ul>
                        <li v-if="planSmartWeeks.length == 0" class="text-center no-result">No smart week found!</li>
                        <li v-for="(week, w) of planSmartWeeks" :key="w">
                            <h3 class="sub_header">
                                {{ week.name }}
                                <button type="button" :disabled="addSmartWeekLoader" class="add_btn pointer ml-auto" @click="handleAddSmartWeek(week.id)">
                                    <i class="fa fa-spinner fa-spin" v-if="addSmartWeekLoader && week.id == selectedWeekId"></i>
                                    <i class="fas fa-plus" v-else></i>
                                    {{ addSmartWeekLoader && week.id == selectedWeekId ? 'Adding' : 'Add' }}
                                </button>
                                <button type="button" :disabled="addSmartWeekLoader" class="delete_btn pointer" @click="handleDeleteSmartWeek(week.id)"><i class="fas fa-trash-alt danger"></i></button>
                            </h3>
                            <ul class="week_info">
                                <li v-for="(setting, s) in week.week_settings" :key="s">
                                    <h6>{{ setting.title }}</h6>
                                    <div class="box_item">
                                        <ol v-if="setting.has_workout">
                                            <li v-for="(workout, wt) in setting.workouts" :key="wt">{{ workout.name }}</li>
                                        </ol>
                                        <p v-else>{{setting.rest_desc}}</p>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Add Smart Week',

        data () {
            return {
                selectedWeekId: 0,
            }
        },

        props: {
            modelValue: Boolean,
            workoutPlanId: Number,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (value) {
                const vm = this;

                vm.toggleScrollBar(value);

                if (value) {
                    if (!vm.planSmartWeeks || !vm.planSmartWeeks.length) {
                        vm.getPlanSmartWeeks();
                    }
                }
            }
        },

        computed: mapState({
            planSmartWeeks: state => state.workoutModule.planSmartWeeks,
            loader: state => state.workoutModule.planSmartWeekLoader,
            addSmartWeekLoader: state => state.workoutModule.createPlanWeekLoader,
        }),


        methods: {
            ...mapActions({
                getPlanSmartWeeks: 'workoutModule/getPlanSmartWeeks',
                addPlanSmartWeek: 'workoutModule/addPlanSmartWeek',
                getWorkoutPlanWeeks: 'workoutModule/getWorkoutPlanWeeks',
                deleteSmartWeek: 'workoutModule/deleteSmartWeek',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleAddSmartWeek (id) {
                const vm          = this;
                const params      = { workout_plan_id: vm.workoutPlanId, smart_week_id: id }
                vm.selectedWeekId = id;

                vm.addPlanSmartWeek(params).then((result) => {
                    if (result) {
                        vm.getWorkoutPlanWeeks({ workout_plan_id: vm.workoutPlanId });
                        vm.closeModal();
                    }
                });
            },

            handleDeleteSmartWeek (id) {
                const vm      = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this workout plan smart week.`, 'Delete');

                options.preConfirm = () => vm.deleteSmartWeek(id);

                Swal.fire(options);
            },
        }
    }
</script>

<style scoped>
    .workout_config .modal_container {
        padding: 0;
        max-width: 700px;
        width: 100%;
    }

    .workout_config .modal_header {
        padding: 20px 30px;
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
    }

    .smart_weeklist .add_btn{
        font-size: 13px;
        line-height: 16px;
    }
    .smart_weeklist .add_btn i {
        margin-right: 5px;
        font-size: 9px !important;
    }
    .smart_weeklist > ul{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .smart_weeklist > ul .week_info{
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;
        padding: 5px 10px 20px 10px;
    }
    .smart_weeklist > ul .week_info > li{
        padding: 5px;
        width: 25%;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    .smart_weeklist > ul .week_info li h6{
        font-size: 9px;
        line-height: 11px;
        color: #121525;
        font-weight: 400;
        margin: 5px 0;
    }
    .smart_weeklist > ul .week_info li .box_item{
        flex-grow: 1;
        min-height: 70px;
        background: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
    }
    .smart_weeklist > ul .week_info li .box_item p{
        font-size: 10px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-top: 3px;
    }
    .smart_weeklist > ul .week_info li .box_item ol{
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    .smart_weeklist > ul .week_info li .box_item ol li{
        list-style-type: inherit;
        font-size: 9px;
        line-height: 12px;
        padding: 3px 8px;
        font-weight: 400;
        color: #5a5a5a;
        background: #fff;
        border-radius: 9px;
    }
    .week_list li .week_panel ul li .box_item p:hover .quick_info, .week_list li .week_panel ul li .box_item ol > li > label:hover .quick_info{
        display: block;
        animation: smoothMove 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
</style>